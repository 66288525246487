.sidebarmenu-linkitem {
  --background: "none";
}

@media (max-width: 767.98px) {
  .sidebarmenu-linkitem {
    --min-height: 44px;
  }
}

.toolbar {
  --background: linear-gradient(#014a90, #012169);
}

.toolbar-noshadow {
  --background: #014a90;
}

.header-noshadow:after {
  background-image: none;
}

.toolbar-light {
  --background: white;
}

.content {
  --background: #f7f7f7;
}

.toolbar-title {
  color: #ffffff;
}

.toolbar-title-light {
  --color: #181818;
}

.split-pane {
  --side-max-width: 300px;
}

.with-print {
  display: none;
}

@media print {
  body {
    overflow: visible;
    position: relative;
  }

  .ion-page, .split-pane, .split-pane-main, .split-pane-md {
    overflow: visible;
    contain: none;
  }

  .with-print {
    display: inline;
  }
  .no-print {
    display: none;
    opacity: 0;
    width: 0;
  }
}
